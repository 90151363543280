import { plainToInstance } from 'class-transformer';
import { FileMetadata, Project, Version } from '~/utilities/pyscript-api-models';
import type {
  ApiKey,
  ApiProxy,
  Collection,
  Favorite,
  GetProfileResponse,
  GetPurchasesResponse,
  GetUserStatsResponse,
  ListCollectionProjectsOptions,
  ListCollectionsOptions,
  ListProjectsOptions,
  ListProjectsResponse,
  PostBodyCreateProject,
  PostBodyForkProject,
  PostCreateApiProxyBody,
  PostCreateSecretBody,
  ProductName,
  ProjectStats,
  PutBodyUpdateProjectMetadata,
  PutBodyUpdateVersion,
  PutCreateApiProxyBody,
  PutCreateSecretBody,
  PutUserProfile,
  RegisterData,
  Secret,
  UserBadge,
  Whoami,
} from '~/utilities/pyscript-api-models';
import { setPsdcRedirect } from '~/utilities/psdc-redirect';
import { objectToQueryParams } from '~/utilities/object-to-query-params';
import { toSnakeCaseKeys } from '~/utilities/snake-case';

/**
 * // TODO: Refactor to make more DRY
 * - Handle 403/404 errors
 *  - https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
 * - Split out common code for GET/POST/PUT/DEL requests
 */

const API_HOST = import.meta.env.PUBLIC_PYSCRIPT_API_HOST;
const API_ACCOUNTS = import.meta.env.PUBLIC_PYSCRIPT_API_ACCOUNTS;
const API_ASSIST = import.meta.env.PUBLIC_PYSCRIPT_API_ASSIST;
const API_AUTH = import.meta.env.PUBLIC_PYSCRIPT_API_AUTH;
const API_CONTENT = import.meta.env.PUBLIC_PYSCRIPT_API_CONTENT;
const API_PAYMENTS = import.meta.env.PUBLIC_PYSCRIPT_API_PAYMENTS;

async function responseHandler(resp: Response) {
  const json = await resp.json();
  if (resp.ok) {
    return json;
  }

  // If the session token has expired, send the user to the homepage to login again.
  if (
    json.error?.code === 'auth_required' ||
    (resp.status === 403 && json.message === 'No JWT token found') ||
    // 'Authorization required' message occurs when saving a file when the session token is expired
    (resp.status === 403 && json.message === 'Authorization required') ||
    (resp.status === 403 && json.message === 'Auth token is not provided or is invalid')
  ) {
    await setPsdcRedirect();
    window.location.href = window.location.origin;
  }

  throw json;
}

async function whoami(): Promise<Whoami | null> {
  const endpoint = `${API_AUTH}/whoami`;
  const resp = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // 403 status means not logged in.
  if (resp.status === 403) {
    return null;
  }

  return resp.json();
}

async function register(data: RegisterData): Promise<Whoami> {
  const endpoint = `${API_AUTH}/register`;
  const resp = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: data.email,
      password: data.password,
      username: data.username,
      optin: data.optin,
    }),
  });

  return await responseHandler(resp);
}

/**
 * Delete a project.
 */
async function deleteProject(projectId: string): Promise<null> {
  const endpoint = `${API_HOST}/${projectId}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Get project's metadata.
 */
async function getProject(projectId: string): Promise<Project> {
  const endpoint = `${API_HOST}/${projectId}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Project, json);
}

/**
 * Get the project with the specified username or id, and project slug.
 */
async function getProjectBySlug(usernameOrUserId: string, projectSlug: string): Promise<Project> {
  const endpoint = `${API_HOST}/${usernameOrUserId}/${projectSlug}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Project, json);
}

/**
 * Get project's stats.
 */
async function getProjectStats(projectId: string): Promise<ProjectStats> {
  const endpoint = `${API_HOST}/${projectId}/stats`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Gets a list of projects in a paginated format. Options can be passed
 * to change pages, search, and sort. The list of projects can be from any user,
 * as well as a particular list such as 'trending', 'featured', or 'favorites'.
 * By default, if no scope is provided, it will return the logged in user's
 * projects.
 */
async function listProjects(
  options: ListProjectsOptions,
  abortSignal?: AbortSignal,
): Promise<ListProjectsResponse> {
  const queryParams = objectToQueryParams(toSnakeCaseKeys(options));
  const endpoint = `${API_HOST}/?${queryParams}`;

  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    signal: abortSignal,
  });
  return await responseHandler(response);
}

/**
 * Get project's versions.
 */
async function listVersions(projectId: string): Promise<Version[]> {
  const endpoint = `${API_HOST}/${projectId}/versions`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance<Version, Version[]>(Version, json.results);
}

/**
 * Create a new project.
 */
async function createProject(body: PostBodyCreateProject): Promise<Project> {
  const endpoint = `${API_HOST}/`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body || {}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);

  return plainToInstance(Project, json);
}

/**
 * Get a version's data, such as its status of 'published' or 'unpublished'.
 */
async function getVersion(projectId: string, version: string): Promise<Version> {
  const endpoint = `${API_HOST}/${projectId}/versions/${version}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Version, json);
}

/**
 * Update a version's data, such as its status of 'published' or 'unpublished'.
 */
async function updateVersion(
  projectId: string,
  version: string,
  data: PutBodyUpdateVersion,
): Promise<Version> {
  const endpoint = `${API_HOST}/${projectId}/versions/${version}`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data || {}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Version, json);
}

/**
 * Creates a new version of the project.
 */
async function createVersion(projectId: string): Promise<Version> {
  const endpoint = `${API_HOST}/${projectId}/versions`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Version, json);
}

/**
 * Deletes a version from a project.
 */
async function deleteVersion(projectId: string, versionIdOrTag: string | number): Promise<Version> {
  const endpoint = `${API_HOST}/${projectId}/versions/${versionIdOrTag}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Version, json);
}

/**
 * Fork a project.
 */
async function forkProject(projectId: string, data: PostBodyForkProject): Promise<Project> {
  const endpoint = `${API_HOST}/${projectId}/forks`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(data || {}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(Project, json);
}

/**
 * Fetches the contents of project's file.
 *
 * @param projectPath E.g. `{user-guid}/{project-guid}/{version}/{file-name}.{extension}`
 */
async function getFileContent(projectPath: string): Promise<Response> {
  const endpoint = `${API_CONTENT}/${encodeURIComponent(projectPath)}`;
  return fetch(endpoint, { cache: 'no-store' });
}

/**
 * Upload a file to a project.
 */
async function uploadFile(
  projectId: string,
  file: FormData,
  overwrite = false,
): Promise<FileMetadata> {
  let endpoint = `${API_HOST}/${projectId}/files`;
  if (overwrite) {
    endpoint += '?overwrite=true';
  }
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: file,
  });

  const json = await responseHandler(response);
  return plainToInstance(FileMetadata, json);
}

/**
 * Update a file in a project. Use this when saving an existing file when the
 * content of that file changes.
 */
async function updateFile(projectId: string, file: FormData): Promise<FileMetadata> {
  const endpoint = `${API_HOST}/${projectId}/files`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    body: file,
  });

  const json = await responseHandler(response);
  return plainToInstance(FileMetadata, json);
}

/**
 * Create an empty folder in a project.
 *
 * The `folderPath` should not end with a slash. The API will handle that addition.
 *
 * E.g. Given a `folderPath` arg of `path/to/folder`, this will result in an empty
 * directory created at `{userId}/{projectId}/{version}/path/to/folder/`.
 *
 * NOTE: Don't add a trailing slash, the API will return a 409 CONFLICT error.
 */
async function createFolder(projectId: string, folderPath: string): Promise<FileMetadata> {
  const endpoint = `${API_HOST}/${projectId}/folders`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ folder_path: folderPath }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await responseHandler(response);
  return plainToInstance(FileMetadata, json);
}

/**
 * Delete a folder from a project.
 *
 * The `folderPath` should not end with a slash. The API will handle that addition.
 *
 * E.g. Given a `folderPath` arg of `path/to/folder`, this will result in an empty
 * directory created at `{userId}/{projectId}/{version}/path/to/folder/`.
 */
async function deleteFolder(projectId: string, folderPath: string): Promise<null> {
  const endpoint = `${API_HOST}/${projectId}/folders?folder_path=${encodeURIComponent(folderPath)}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Deletes a file or folder from a project.
 */
async function deleteFile(projectId: string, filePath: string): Promise<null> {
  const endpoint = `${API_HOST}/${projectId}/files?file_path=${encodeURIComponent(filePath)}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Get a list file metadata for a project.
 */
async function listFiles(projectId: string, version = 'latest'): Promise<FileMetadata[]> {
  const endpoint = `${API_HOST}/${projectId}/files?version=${version}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await responseHandler(response);
  return plainToInstance<FileMetadata, FileMetadata[]>(FileMetadata, json);
}

/**
 * Get a list file metadata for a project.
 *
 * If it's a folder, don't include the trailing slash or else the API will
 * return an 409 CONFLICT error.
 */
async function renameFileOrFolder(
  projectId: string,
  oldPath: string,
  newPath: string,
): Promise<null> {
  const endpoint = `${API_HOST}/${projectId}/files`;
  const response = await fetch(endpoint, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify({
      old_path: oldPath,
      new_path: newPath,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Update project.
 */
async function updateProject(
  projectId: string,
  data: PutBodyUpdateProjectMetadata,
): Promise<Project> {
  const endpoint = `${API_HOST}/${projectId}`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data || {}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Upload a project icon.
 */
async function uploadProjectIcon(projectId: string, data: FormData): Promise<Project> {
  const endpoint = `${API_HOST}/${projectId}/icon`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: data,
  });
  return await responseHandler(response);
}

/**
 * Starts the checkout flow given a list of product IDs. The product ID isn't
 * the SKU, it's the human readable name, such as "pyscript_founder".
 */
async function checkout(product_names: ProductName[]): Promise<{ url: string }> {
  const endpoint = `${API_PAYMENTS}/checkout`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ product_names }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * When this method is called, the PSDC API will redirect the user to
 * the Stripe Checkout where they can purchase the Founders Plan.
 */
async function checkoutFoundersPlan() {
  const { url } = await checkout(['pyscript_founder']);

  if (url) {
    window.location.href = url;
  }
}

/**
 * Fetch a user's purchase history.
 */
async function purchases(): Promise<GetPurchasesResponse[]> {
  const endpoint = `${API_PAYMENTS}/purchases`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Fetch a user's badges.
 */
async function listUserBadges(user: string): Promise<UserBadge[]> {
  const endpoint = `${API_ACCOUNTS}/${user}/badges`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Fetch a user's profile.
 */
async function getProfile(user: string): Promise<GetProfileResponse> {
  const endpoint = `${API_ACCOUNTS}/${user}/profile`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

async function updateProfile(user: string, data: PutUserProfile): Promise<GetProfileResponse> {
  const endpoint = `${API_ACCOUNTS}/${user}/profile`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

async function uploadProfilePicture(user: string, data: FormData) {
  const endpoint = `${API_ACCOUNTS}/${user}/profile/image`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: data,
  });
  return await responseHandler(response);
}

/**
 * Fetch a user's stats.
 */
async function userStats(user: string): Promise<GetUserStatsResponse> {
  const endpoint = `${API_ACCOUNTS}/${user}/stats`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Delete a user's account.
 */
async function deleteAccount(user: string): Promise<null> {
  const endpoint = `${API_ACCOUNTS}/${user}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
  });
  return await responseHandler(response);
}

/**
 * Logout a user.
 *
 * This will invalidate the session token and redirect the user to the homepage.
 */
function logout() {
  window.location.href = `${API_AUTH}/logout`;
}

/**
 * Query the PyScript Assistant.
 */
async function assistantPrompt(text: string) {
  const endpoint = `${API_ASSIST}/prompt`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      text,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Project shared. Used for tracking number of times a project is shared.
 */
async function shareProject(projectId: string, via: 'url' | 'linkedin' | 'twitter') {
  const endpoint = `${API_HOST}/${projectId}/shares`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      via,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Create an API key for the user.
 */
async function createApiKey(name: string, expiry: number | null): Promise<string> {
  const endpoint = `${API_ACCOUNTS}/api-keys`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      expiry,
    }),
  });
  return await responseHandler(response);
}

/**
 * List the user's API keys.
 */
async function listApiKeys(): Promise<ApiKey[]> {
  const endpoint = `${API_ACCOUNTS}/api-keys`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Delete an API key for the user.
 */
async function deleteApiKey(id: string): Promise<string> {
  const endpoint = `${API_ACCOUNTS}/api-keys/${id}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Create a secret for the user.
 */
async function createSecret(body: PostCreateSecretBody): Promise<Secret> {
  const endpoint = `${API_ACCOUNTS}/secrets`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body || {}),
  });
  return await responseHandler(response);
}

/**
 * List the user's secrets.
 */
async function listSecrets(): Promise<Secret[]> {
  const endpoint = `${API_ACCOUNTS}/secrets`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Delete a secret for the user.
 */
async function deleteSecret(id: string): Promise<string> {
  const endpoint = `${API_ACCOUNTS}/secrets/${id}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Get a secret for the user.
 */
async function getSecret(id: string): Promise<Secret> {
  const endpoint = `${API_ACCOUNTS}/secrets/${id}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Update a secret for the user.
 */
async function updateSecret(id: string, body: PutCreateSecretBody): Promise<string> {
  const endpoint = `${API_ACCOUNTS}/secrets/${id}`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body || {}),
  });
  return await responseHandler(response);
}

/**
 * Create an API proxy for the user.
 */
async function createApiProxy(body: PostCreateApiProxyBody): Promise<ApiProxy> {
  const endpoint = `${API_ACCOUNTS}/api-proxies`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body || {}),
  });
  return await responseHandler(response);
}

/**
 * List the user's API Proxies.
 */
async function listApiProxies(): Promise<ApiProxy[]> {
  const endpoint = `${API_ACCOUNTS}/api-proxies`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Delete an API Proxy for the user.
 */
async function deleteApiProxy(id: string): Promise<string> {
  const endpoint = `${API_ACCOUNTS}/api-proxies/${id}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Get an API Proxy for the user.
 */
async function getApiProxy(id: string): Promise<ApiProxy> {
  const endpoint = `${API_ACCOUNTS}/api-proxies/${id}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await responseHandler(response);
}

/**
 * Update an API Proxy for the user.
 */
async function updateApiProxy(id: string, body: PutCreateApiProxyBody): Promise<ApiProxy> {
  const endpoint = `${API_ACCOUNTS}/api-proxies/${id}`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body || {}),
  });
  return await responseHandler(response);
}

/**
 * Get a list of favorites.
 */
async function listFavorites(): Promise<Favorite[]> {
  const endpoint = `${API_HOST}/favorites`;

  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/**
 * Add a project to favorites.
 */
async function addFavorite(projectId: string): Promise<Project> {
  const endpoint = `${API_HOST}/favorites`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      project_id: projectId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/**
 * Removes a project from favorites.
 */
async function removeFavorite(projectId: string): Promise<null> {
  const endpoint = `${API_HOST}/favorites/${projectId}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/** Gets a user's list of collections. */
async function listCollections(options: ListCollectionsOptions): Promise<Collection[]> {
  const queryParams = options ? `?${objectToQueryParams(toSnakeCaseKeys(options))}` : '';
  const endpoint = `${API_HOST}/collections${queryParams}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/** Creates a new collection. */
async function createCollection(
  name: string,
  description: string,
  privacy: boolean,
): Promise<Collection> {
  const endpoint = `${API_HOST}/collections`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: name?.trim(),
      description: description?.trim(),
      private: privacy,
    }),
  });
  return responseHandler(response);
}

/** Update an existing collection. */
async function updateCollection(
  collectionId: string,
  name: string,
  description: string,
  privacy: boolean,
): Promise<Collection> {
  const endpoint = `${API_HOST}/collections/${collectionId}`;
  const response = await fetch(endpoint, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: name.trim(),
      description: description.trim(),
      private: privacy,
    }),
  });
  return responseHandler(response);
}

/** Delete's a logged-in user's collection. */
async function deleteCollection(collectionId: string): Promise<null> {
  const endpoint = `${API_HOST}/collections/${collectionId}`;
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/** List projects in a collection. */
async function listProjectsInCollection(
  collectionId: string,
  options?: ListCollectionProjectsOptions,
): Promise<ListProjectsResponse> {
  const queryParams = options ? `?${objectToQueryParams(toSnakeCaseKeys(options))}` : '';
  const endpoint = `${API_HOST}/collections/${collectionId}${queryParams}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return responseHandler(response);
}

/** Add projects to a collection. */
async function addProjectsToCollection(collectionId: string, projectIds: string[]): Promise<null> {
  const endpoint = `${API_HOST}/collections/${collectionId}`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      project_ids: projectIds,
    }),
  });
  return responseHandler(response);
}

/** Remove projects from a collection. */
async function removeProjectsFromCollection(
  collectionId: string,
  projectIds: string[],
): Promise<null> {
  const endpoint = `${API_HOST}/collections/${collectionId}/remove-projects`;
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      project_ids: projectIds,
    }),
  });
  return responseHandler(response);
}

/** Export a project. */
async function exportProject(projectId: string): Promise<Response> {
  const endpoint = `${API_HOST}/${projectId}/export`;
  return fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
  });
}

export default {
  // Favorites
  listFavorites,
  addFavorite,
  removeFavorite,

  // Files
  deleteFile,
  getFileContent,
  listFiles,
  renameFileOrFolder,
  uploadFile,
  updateFile,
  createFolder,
  deleteFolder,

  // Projects
  createProject,
  deleteProject,
  forkProject,
  getProject,
  getProjectBySlug,
  getProjectStats,
  listProjects,
  updateProject,
  uploadProjectIcon,
  shareProject,
  exportProject,

  // Versions
  createVersion,
  deleteVersion,
  getVersion,
  listVersions,
  updateVersion,

  // Auth
  whoami,
  register,

  // Checkout
  checkout,
  checkoutFoundersPlan,
  purchases,

  // Pyscript Assistant
  assistantPrompt,

  // Accounts.
  getProfile,
  listUserBadges,
  userStats,
  updateProfile,
  uploadProfilePicture,
  deleteAccount,
  logout,

  // Secrets
  createApiKey,
  listApiKeys,
  deleteApiKey,
  createSecret,
  listSecrets,
  deleteSecret,
  getSecret,
  updateSecret,

  // Proxies
  createApiProxy,
  listApiProxies,
  deleteApiProxy,
  getApiProxy,
  updateApiProxy,

  // Collections
  listCollections,
  createCollection,
  updateCollection,
  deleteCollection,
  listProjectsInCollection,
  addProjectsToCollection,
  removeProjectsFromCollection,
};
